<template>
    <div class="box">
        <div class="listbox">
            <div class="listone" v-if="type==0">
                <div class="titlebox">
                    <div class="title">{{$t('service')}}</div>
                    <div class="icon">
                        <img src="../assets/icon2.png" alt="">
                    </div>
                </div>
                <div class="text">{{$t('serviceText')}}</div>
                <div  class="imgbox">
                    <div class="imgs" v-for="(item,index) in imglist[0]" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
            </div>
            <div class="listone" v-if="type==1">
                <div class="titlebox">
                    <div class="title">{{$t('team')}}</div>
                    <div class="icon">
                        <img src="../assets/icon2.png" alt="">
                    </div>
                </div>
                <div class="text">{{$t('teamText')}}</div>
                <div  class="imgbox">
                    <div class="imgs" v-for="(item,index) in imglist[1]" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
            </div>
            <div class="listone" v-if="type==2">
                <div class="titlebox">
                    <div class="title">{{$t('future')}}</div>
                    <div class="icon">
                        <img src="../assets/icon2.png" alt="">
                    </div>
                </div>
                <div class="text">{{$t('futureText')}}</div>
                <div  class="imgbox">
                    <div class="imgs" v-for="(item,index) in imglist[2]" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
            </div>
            <div class="not"> {{$t('onlineText')}}</div>
            
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            type:0,
            imglist:[
                [require("../assets/info/1.png"),require("../assets/info/2.png"),require("../assets/info/3.png")],
                [require("../assets/info1/1.png"),require("../assets/info1/2.png"),require("../assets/info1/3.png")],
                [require("../assets/info2/1.png"),require("../assets/info2/2.png"),require("../assets/info2/3.png")]

            ]
        }
    },
    created(){
        this.type=this.$route.query.type
    },
}
</script>
<style lang="scss" scoped>
.box {
    .listbox {
        width: 80%;
        margin: 20px auto;

        .listone {
            padding-bottom: 30px;
            margin-bottom: 30px;
            .titlebox {
                padding-bottom: 20px;
                padding-left: 20px;

                position: relative;
                .title {
                    font-weight: bold;
                    font-size: 26px;
                    color: #415aab;
                }
                .icon {
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100px;
                    img {
                        width: 100%;
                    }
                }
            }
            .text {
                font-size: 22px;
                color: rgba(5, 18, 61, 0.8);
                line-height: 30px;
                letter-spacing: 1px;
                text-indent: 2em;
                margin: 20px;
            }
            .imgbox{
                display: grid;
                width: 100%;
                grid-gap: 20px;
                grid-template-columns: repeat(3,auto);
                .imgs{
                    img{
                        width: 100%;
                    }
                }
            }
        }
        .not{
            font-size: 14px;
            color: rgba(5, 18, 61, 0.8);
        }
    }
}
</style>